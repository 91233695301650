<template>
    <div style="display: flex; flex-wrap: wrap; flex-direction: column">
        <company-selector
            :text-label="$t('companies.companyLogo')"
            @select:company="company= $event"
        />

        <a
            style="overflow: hidden; max-width: 90%"
            :href="getUrl"
            target="_blank"
        >
            {{ getUrl }}
        </a>

        <div>
            <c-btn
                :outlined="false"
                text
                large
                :title="$t('common.copyLink')"
                @click="copyText"
            />
        </div>
    </div>
</template>

<script>

export default {
    name: "InfoBoardDiceQuestion",
    components: {
        CBtn: () => import('@/components/base/c-btn'),
        CompanySelector: () => import('@/components/admin/companies/companySelector')
    },
    props: {
        gameId: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data: function () {
        return {
            urlBase: "https://gameboard.3weeksconsulting.com/games/board-dice-questions/",
            company: null
        }
    },
    computed: {
        getUrl() {
            return `${this.urlBase}?questions=${this.gameId}&banner-logo=${this.getLogoSelected}`;
        },
        getLogoSelected() {
            if (!this.company) return '';
            return this.company?.attributes?.logo ?? '';
        }
    },
    methods: {
        copyText() {
            navigator.clipboard.writeText(this.getUrl);

            this.$store.dispatch('snackbar/success', this.$t('common.text-copied'))
        }
    }
}
</script>

<style scoped>

</style>
